import styles from './StatusLight.module.scss';
import { useContext, useEffect, useState } from 'react';
import { Package } from '../../model/Package';
import { differenceInDays, differenceInMinutes, isSameDay } from 'date-fns'
import { EntriesContext } from '../../../../shared/EntriesState';

export interface IStatusLightProps {
    date?: number
    package?: Package
}

const StatusLight = (props: IStatusLightProps) => {
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isUnhandled, setIsUnhandled] = useState<boolean>(false);
    const {dispatch} = useContext(EntriesContext);

    useEffect(() => {
        if (props.date) {
            dispatch!({
                date: props.date,
                valid: isValid
            })
        }
    }, [isValid]);

    useEffect(() => {
        if (!props.date || !props.package) {
            setIsUnhandled(true);
            return;
        }

        const interval = setInterval(() => {
            checkValidity();
        }, 5000)
        return () => {
            if (interval) {
                clearTimeout(interval);
            }
        };
    }, [props.date, props.package]);

    const checkValidity = () => {
        if (!props.date) {
            return;
        }
        switch (props.package) {
            case Package.MIN_30:
                const checkMin30 = differenceInMinutes(new Date(), props.date);
                setIsValid(() => checkMin30 < 30);
                break;
            case Package.MIN_60:
                const checkMin60 = differenceInMinutes(new Date(), props.date);
                setIsValid(() => checkMin60 < 60);
                break;
            case Package.DAY:
                const sameDay = isSameDay(props.date, new Date());
                setIsValid(() => sameDay);
                break;
            case Package.WEEK:
                const checkDays7 = differenceInDays(new Date(), props.date);
                setIsValid(() => checkDays7 < 7);
                break;
            case Package.MONTH:
                const checkDays30 = differenceInDays(new Date(), props.date);
                setIsValid(() => checkDays30 < 30);
                break;
        }
    }
    const getClass = (): string => {
        return isUnhandled ?
            styles.unhandled : isValid ?
                styles.valid : styles.invalid
    }
    return (
        <div
            className={`${styles.light} ${getClass()}`}/>
    )
}

export default StatusLight;
