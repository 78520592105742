import React, { useReducer } from 'react';
import styles from './App.module.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { AppContext } from './shared/AppState';
import { ENTRIES_DEFAULT_STATE, EntriesContext, entriesReducer } from './shared/EntriesState';
import Entries from './feature/entries/pages/entries/Entries';

require('./shared/AmplifyConfig');
require('./shared/AxiosConfig');

function App() {

    const [state, dispatch] = useReducer(entriesReducer, ENTRIES_DEFAULT_STATE.state);

    return (
        <div className={styles.appWrapper}>
            <Authenticator hideSignUp={true}>
                {({signOut, user}) => (
                    <AppContext.Provider value={{signOut, user}}>
                        <EntriesContext.Provider value={{state, dispatch}}>
                            <Routes>
                                <Route path='/home' element={<Entries/>}/>
                                <Route path='*' element={<Navigate to='home'/>}/>
                            </Routes>
                        </EntriesContext.Provider>
                    </AppContext.Provider>
                )}
            </Authenticator>
        </div>
    );
}

export default App;
