import { Entry } from '../../model/Entry';
import styles from './Table.module.scss'
import { format } from 'date-fns';
import StatusLight from '../status-light/StatusLight';

export interface ITableProps {
    entries: Entry[]
}

const Table = (props: ITableProps) => {
    const entries = props.entries;

    return (
        <div className={styles.table}>
            {
                entries.map((entry, index) => (
                    <div className={`${styles.row} ${index % 2 === 0 && styles.even}`} key={entry.timestamp}>
                        <div><b>Emri: </b>{entry.name}</div>
                        <div>
                            <b>Hyrja: </b>{entry.timestamp ?
                            format(new Date(entry.timestamp), 'dd/MM/yy HH:mm') :
                            'unknown'}
                        </div>
                        <div><b>ID: </b>{entry.id}</div>
                        <div><b>Kohezgjatja: </b>{entry.package}</div>
                        <div><b>Shenim: </b>{entry.note || '-'}</div>
                        <div className={styles.status}><b>Statusi: </b>
                            <StatusLight date={entry.timestamp}
                                         package={entry.package}/>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Table;
