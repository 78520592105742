import { CognitoUser } from 'amazon-cognito-identity-js';
import React from 'react';

export interface CognitoAttributes {
    email: string;
    phone_number: string;

    [key: string]: string;
}

export interface CognitoUserAmplify extends CognitoUser {
    username?: string;
    attributes?: CognitoAttributes;
}

export interface IAppContext {
    signOut?: () => any,
    user?: CognitoUserAmplify
}


const AppContext = React.createContext<IAppContext>({
    signOut: undefined,
    user: undefined
})


export { AppContext };
