import { Alert, Button, Loader, SelectField, TextField, TextAreaField } from '@aws-amplify/ui-react'
import { Package } from '../../model/Package';
import styles from './NewEntry.module.scss';
import React, { useRef, useState } from 'react';
import { Entry } from '../../model/Entry';
import { createEntry } from '../../api/EntriesApi';

export interface INewEntryProps {
    onCreated: (e: Entry) => void
}

const NewEntry = (props: INewEntryProps) => {
    const nameRef = useRef<HTMLInputElement | null>(null);
    const idRef = useRef<HTMLInputElement | null>(null);
    const packageRef = useRef<HTMLSelectElement | null>(null);
    const noteRef = useRef<HTMLTextAreaElement | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const onFormSubmit = (event: React.FormEvent): void => {
        event.preventDefault();
        setLoading(true);
        const name = nameRef.current?.value;
        const id = idRef.current?.value;
        const time = packageRef.current?.value;
        const note = noteRef.current?.value;

        const entry: Entry = {
            name,
            id,
            package: Package[time as keyof typeof Package],
            note
        };
        createEntry(entry).then(e => {
            setLoading(false);
            props.onCreated(e);
        }).catch(err => {
            setLoading(false);
            setError(err.message);
        });
    }
    return (
        <form onSubmit={(e) => onFormSubmit(e)}
              className={styles.form}>
            <div>
                <TextField ref={nameRef} placeholder={'Emri'} label={'Emri'} isRequired={true}/>
            </div>
            <div>
                <TextField ref={idRef} placeholder={'ID - Nr i shiritit'} label={'ID'} isRequired={true}/>
            </div>
            <div>
                <SelectField ref={packageRef} label={'Kohezgjatja'}>
                    {
                        Object.values(Package).map((key, i) => (
                            <option value={key} key={i}>{key}</option>
                        ))
                    }
                </SelectField>
            </div>
            <div>
                <TextAreaField ref={noteRef} placeholder={'Shembull: Grup 5 veta, totali 30 euro'} label={'Shenim - Jo i detyruar'} isRequired={false}/>
            </div>
            {
                !!error && <Alert variation={'error'}>{error}</Alert>
            }
            {
                loading ?
                    <Loader className={styles.loader} size={'large'}/> :
                    <Button type={'submit'} variation={'primary'}>Ruaj Hyrjen</Button>
            }
        </form>
    )
}

export default NewEntry;
