import { createPortal } from 'react-dom';
import styles from './Portal.module.scss';
import React, { ComponentProps } from 'react';

export interface IPortalProps extends ComponentProps<any> {
    onClose: () => void
}

const Portal = (props: React.PropsWithChildren<IPortalProps>) => {
    const onCloseRequested = (event: React.MouseEvent): void => {
        if (event.target === event.currentTarget) {
            props.onClose();
        }
    }
    return createPortal(
        <div className={styles.wrapper} onClick={(e: React.MouseEvent) => onCloseRequested(e)}>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>,
        document.getElementById('portal') as HTMLElement
    )
}
export default Portal;
