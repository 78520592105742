import { Entry } from '../model/Entry';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const getEntries = (): Promise<Entry[]> => {
    return axios.get<Entry[]>(API_URL + `/v1/entry`).then(res => res.data).catch(err => {
        console.log('Unhandled err: ', err)
        return [];
    });
}

const createEntry = (entry: Entry): Promise<Entry> => {
    return axios.post<Entry>(API_URL + `/v1/entry`, entry).then(res => res.data);
}

export { getEntries, createEntry }
