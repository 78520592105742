import React from 'react';

export interface IEntriesContext {
    state: Map<number, boolean>,
    dispatch: React.Dispatch<IEntryChange> | null
}

export interface IEntryChange {
    date: number,
    valid: boolean
}

const ENTRIES_DEFAULT_STATE: IEntriesContext = {
    state: new Map<number, boolean>(),
    dispatch: null
}

const EntriesContext = React.createContext(ENTRIES_DEFAULT_STATE);

const entriesReducer = (oldState: Map<number, boolean>, change: IEntryChange) => {
    oldState.set(change.date, change.valid);
    return new Map(oldState);
}

export { EntriesContext, entriesReducer, ENTRIES_DEFAULT_STATE }
