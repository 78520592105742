import React, { useContext, useEffect, useState } from 'react';
import { Entry } from '../../model/Entry';
import { getEntries } from '../../api/EntriesApi';
import styles from './Entries.module.scss';
import { AppContext } from '../../../../shared/AppState';
import { Button, Heading } from '@aws-amplify/ui-react';
import Portal from '../../components/portal/Portal';
import Table from '../../components/table/Table';
import NewEntry from '../../components/new-entry/NewEntry';
import { EntriesContext } from '../../../../shared/EntriesState';

const Entries = () => {
    const [entries, setEntries] = useState<Entry[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [totalValid, setTotalValid] = useState<number>(0);
    const {user, signOut} = useContext(AppContext);
    const {state} = useContext(EntriesContext);

    useEffect(() => {
        const fetchEntries = async (): Promise<void> => {
            const data = await getEntries();
            setEntries(data);
        }
        fetchEntries();
    }, []);

    const onNewEntry = (e: Entry): void => {
        setShowModal(false);
        setEntries(old => [...old, e]);
    }

    useEffect(() => {
        let totValid = 0;
        state.forEach(v => {
            if (v) {
                totValid += 1
            }
        });
        setTotalValid(totValid);
    }, [state])

    return (
        <div className={styles.container}>
            <section className={styles.header}>
                <Heading>{user?.username}</Heading>
                <div className={styles.activeEntries}>{totalValid}</div>
                <Button onClick={() => signOut && signOut()}>Sign Out</Button>
            </section>
            <section className={styles.tableContainer}>
                <Table entries={entries}/>
            </section>
            <section className={styles.newEntryContainer}>
                <Button variation={'primary'} color={'#fdd835'}
                        isFullWidth={true} onClick={() => setShowModal(true)}>Hyrje e re</Button>
            </section>
            {
                showModal && <Portal onClose={() => setShowModal(false)}>
                    <NewEntry onCreated={e => onNewEntry(e)}/>
                </Portal>
            }
        </div>
    )
}

export default Entries;
