import { Amplify } from 'aws-amplify';

Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_POOL,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        storage: window.localStorage,
        mandatorySignIn: true
    }
});
