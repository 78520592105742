import { Auth } from 'aws-amplify';
import { AxiosRequestConfig } from 'axios';

const axios = require('axios');

axios.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        const token = await getToken();
        config.headers = {
            'Content-Type': 'application/json',
            'authorization': token
        }
        return config;
    }, (error: any) => Promise.reject(error)
)

const getToken = async (): Promise<string> => {
    try {
        const session = await Auth.currentSession();
        return session.getAccessToken().getJwtToken();
    } catch {
        return ''
    }
}
